import React from 'react';
import cn from 'classnames';
import Image from 'next/image';
import PropTypes from 'prop-types';
import useMediaProps from './utils/useMediaProps';
import styles from './styles.module.scss';

const Media = (props) => {
  const {
    ref,
    url,
    isVideo,
    className,
    imageProps,
    playControl,
    posterUrl,
    ...rest
  } = useMediaProps(props);

  if (isVideo) {
    if (playControl) {
      return (
        <div className={styles['video-container']}>
          <video
            ref={ref}
            controls
            poster={posterUrl}
            className={className}
            {...rest}
          >
            <source
              src={url}
              type="video/mp4"
            />
            <source
              src={url}
              type="video/webm"
            />
          </video>
        </div>
      );
    }

    return (
      <video
        ref={ref}
        muted
        autoPlay
        loop
        playsInline
        className={className}
        {...rest}
      >
        <source
          src={url}
          type="video/webm"
        />
        <source
          src={url}
          type="video/mp4"
        />
      </video>
    );
  }

  return (
    <picture className={cn(styles.image, className)}>
      <Image {...imageProps} />
    </picture>
  );
};

Media.propTypes = {
  asset: PropTypes.instanceOf(Object).isRequired,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  playsInline: PropTypes.bool,
  layout: PropTypes.oneOf([
    'fill',
    'intrinsic',
    'fixed',
    'responsive',
  ]),
};

export default Media;
