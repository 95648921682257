import cn from 'classnames';
import SectionTitle from 'UI/components/SectionTitle';
import { getDocumentFields } from 'utils/helper';
import styles from './styles.module.scss';
import ScrollingElement from './ScrollingElement';

const InfinityScrollingCarousel = ({
  section,
  type,
}) => {
  const {
    title,
    description,
    contentModules,
    view,
  } = getDocumentFields(
    section,
    [
      'title',
      'contentModules',
      'view',
      'description',
    ],
  );

  const items = contentModules.map((item) => {
    const {
      images,
      ...rest
    } = getDocumentFields(
      item,
      [
        'images',
        'title',
        'description',
      ],
    );

    return {
      image: images?.[0],
      ...rest,
    };
  });

  return (
    <section
      className={cn(
        styles.section,
        styles[type],
        styles[view],
      )}
    >
      <SectionTitle
        title={title}
        description={description}
        className={styles.title}
      />
      <div className={styles.scrollingContainer}>
        {Array.from({ length: 4 }).map((_, i) => (
          <ScrollingElement
            key={`scrolling-element-${i}`}
            items={items}
          />
        ))}
      </div>
    </section>
  );
};

export default InfinityScrollingCarousel;
