import cn from 'classnames';
import Media from 'UI/components/Media';
import styles from '../styles.module.scss';

const ScrollingElement = ({ items }) => (
  <div className={styles.scrollingElement}>
    {(items || []).map((item, i) => (
      <div
        key={`scrolling-item-${i}`}
        className={cn(
          styles.item,
          styles[`item-${i + 1}`],
        )}
      >
        {item.image && (
          <Media
            asset={item.image}
            className={cn(styles.itemImage, styles[`itemImage-${i + 1}`])}
          />
        )}
        {item.title && (
          <p className={styles.itemTitle}>{item.title}</p>
        )}
        {item.description && (
          <p className={styles.itemDescription}>{item.description}</p>
        )}
      </div>
    ))}
  </div>
);

export default ScrollingElement;
